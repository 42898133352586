import { __awaiter, __generator } from "tslib";
import { defineComponent, ref, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { screenWidth, scrollUpToClass, } from "@/core/helpers/custom";
import { useRouter } from "vue-router";
import { debounce } from "lodash";
import BaseTable from "@/components/BaseTable.vue";
import { orderList, reOrder } from "@/api/OrderApi";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n/index";
export default defineComponent({
    name: "order-index",
    components: {
        BaseTable: BaseTable,
    },
    setup: function () {
        var _this = this;
        var data = ref([]);
        var query = ref({
            page: 1,
            search: "",
            child_product: "yes",
            limit: 6,
        });
        var loading = ref(false);
        var router = useRouter();
        var currentScreenWidth = ref(0);
        var pagination = ref({});
        var t = useI18n().t;
        var headerConfig = computed(function () {
            if (currentScreenWidth.value > 425) {
                return [
                    {
                        name: "Order ID",
                        key: "id",
                        sortable: true,
                    },
                    {
                        name: "Invoice",
                        key: "invoice_no",
                        sortable: true,
                    },
                    {
                        name: "Customer",
                        key: "customer_fullname",
                        sortable: true,
                    },
                    {
                        name: "Status",
                        key: "order_status",
                        sortable: true,
                    },
                    {
                        name: "Total",
                        key: "total",
                        sortable: true,
                    },
                    {
                        name: "Date Added",
                        key: "date_added",
                        sortable: true,
                    },
                    {
                        name: "Actions",
                        key: "actions",
                    },
                ];
            }
            else {
                return [
                    // {
                    //   name: "Order ID",
                    //   key: "id",
                    //   sortable: true,
                    // },
                    {
                        name: "Invoice",
                        key: "invoice_no",
                        sortable: true,
                    },
                    // {
                    //   name: "Customer",
                    //   key: "customer_fullname",
                    //   sortable: true,
                    // },
                    // {
                    //   name: "Status",
                    //   key: "order_status",
                    //   sortable: true,
                    // },
                    // {
                    //   name: "Total",
                    //   key: "total",
                    //   sortable: true,
                    // },
                    // {
                    //   name: "Date Added",
                    //   key: "date_added",
                    //   sortable: true,
                    // },
                    {
                        name: "Actions",
                        key: "actions",
                    },
                ];
            }
        });
        var searchByKeyword = debounce(function () {
            getData();
        }, 500);
        var resetSearch = function () {
            query.value.search = "";
            getData();
        };
        var getData = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value = true;
                        // topLoadingToggle(true);
                        return [4 /*yield*/, orderList(query.value).then(function (res) {
                                data.value = ref(res.data.data).value;
                                pagination.value = {
                                    page: res.data.meta.current_page,
                                    total: res.data.meta.total,
                                    rowsPerPage: res.data.meta.per_page,
                                    totalPage: res.data.meta.last_page,
                                };
                                scrollUp("order_card");
                            })];
                    case 1:
                        // topLoadingToggle(true);
                        _a.sent();
                        loading.value = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var changePage = function (page) {
            query.value.page = page;
            getData();
        };
        var resizeScreen = debounce(function () {
            currentScreenWidth.value = screenWidth();
        }, 100);
        var reorder = function (order) {
            Swal.fire({
                title: t("Are you sure you want to reorder?"),
                showCancelButton: true,
                confirmButtonText: t("Yes"),
                cancelButtonText: t("Cancel"),
                confirmButtonColor: "#00AF27",
            }).then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.isConfirmed) return [3 /*break*/, 2];
                            return [4 /*yield*/, reOrder(order.id).then(function (_a) {
                                    var data = _a.data;
                                    router.push({
                                        name: "checkout",
                                        params: {
                                            orderId: order.id,
                                        },
                                    });
                                })];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        };
        var scrollUp = function (className) {
            scrollUpToClass(className);
        };
        // const scrollUpToClass = () => {
        //   setTimeout(() => {
        //     const firstError = document.getElementsByClassName("order_card");
        //     if (firstError.length > 0) {
        //       firstError[0].scrollIntoView({
        //         behavior: "smooth",
        //       });
        //     }
        //   }, 250);
        // };
        onMounted(function () {
            setCurrentPageBreadcrumbs("Order Listing", ["Order"]);
            getData();
            resizeScreen();
            window.addEventListener("resize", resizeScreen);
        });
        return {
            data: data,
            headerConfig: headerConfig,
            pagination: pagination,
            changePage: changePage,
            loading: loading,
            router: router,
            searchByKeyword: searchByKeyword,
            query: query,
            resetSearch: resetSearch,
            currentScreenWidth: currentScreenWidth,
            reorder: reorder,
        };
    },
});
